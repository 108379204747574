import classNames from "classnames";

import styles from "./OnePlusOneLabel.module.scss";
import { JSX } from "react";

interface OnePlusOneLabelProps {
  className?: string;
  title?: string | JSX.Element;
}

const OnePlusOneLabel = ({
  className = "",
  title = "1+1=3",
}: OnePlusOneLabelProps) => {
  return <div className={classNames(styles.root, className)}>{title}</div>;
};

export default OnePlusOneLabel;
