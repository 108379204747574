import classNames from "classnames";
import { MutableRefObject } from "react";
import SwiperCore, { Lazy, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { SlideSimple } from "@/api/generatedTypes";

import styles from "./BaseCarousel.module.scss";
import { BaseSlide } from "./BaseSlide";

SwiperCore.use([Pagination, Lazy, Autoplay]);

interface BaseCarouselProps {
  slides: SlideSimple[];
  slideClassName?: string;
  videoClassName?: string;
  initialSlide?: number;
  swiperRef?: MutableRefObject<SwiperCore | null | undefined>;
  autoplay?: boolean;
}

const AUTOPLAY_DELAY = 4000;

const BaseCarousel = ({
  slides = [],
  slideClassName,
  videoClassName,
  initialSlide = 0,
  swiperRef,
  autoplay = false,
}: BaseCarouselProps) => {
  if (slides.length === 1) {
    return (
      <BaseSlide
        slide={slides[0]}
        slideClassName={slideClassName}
        videoClassName={videoClassName}
        onClick={slides[0].onClick}
      />
    );
  }

  return (
    <Swiper
      onSwiper={(swiper) => {
        if (swiperRef) swiperRef.current = swiper;
      }}
      className={styles.root}
      preloadImages={false}
      lazy={{ loadPrevNext: true, loadPrevNextAmount: 2 }}
      modules={[Pagination]}
      slidesPerView={1}
      touchReleaseOnEdges={true}
      pagination={{
        clickable: true,
        bulletClass: "swiper-pagination-bullet swiper-styled-pagination-bullet",
        bulletActiveClass:
          "swiper-pagination-bullet-active swiper-styled-pagination-bullet_active",
      }}
      spaceBetween={8}
      loop
      initialSlide={initialSlide}
      autoplay={
        autoplay && {
          delay: AUTOPLAY_DELAY,
          disableOnInteraction: false,
        }
      }
    >
      {slides.map((slide) => (
        <SwiperSlide key={slide.id}>
          {({ isActive }) => (
            <BaseSlide
              slide={slide}
              slideClassName={classNames(styles.slide, slideClassName)}
              videoClassName={videoClassName}
              isActive={isActive}
              onClick={slide.onClick}
            />
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default BaseCarousel;
