import classNames from "classnames";

import { useToastHandlers } from "@/components/toaster";

import styles from "./CheckboxField.module.scss";

interface CheckboxFieldProps {
  label: string;
  checked: boolean;
  toggleChecked(): void;
  disabled?: boolean;
  disabledMessage?: DisabledMessage;
  className?: string;
}

const CheckboxField = ({
  label,
  checked,
  toggleChecked,
  disabled = false,
  disabledMessage,
  className = "",
}: CheckboxFieldProps) => {
  const { addToast } = useToastHandlers();

  const onClickHandler =
    disabled && disabledMessage
      ? () =>
          addToast({
            title: disabledMessage?.title,
            text: disabledMessage?.body,
            variant: "base",
            delay: 10000,
          })
      : undefined;

  return (
    <label
      className={classNames(styles.root, className, {
        [styles.disabled]: disabled,
      })}
      onClick={onClickHandler}
    >
      <input
        type="checkbox"
        checked={checked}
        onChange={toggleChecked}
        className={styles.realInput}
        disabled={disabled}
      />
      <span
        className={classNames(styles.checkbox, {
          [styles.checked]: checked,
        })}
      />
      <span className={styles.text}>{label}</span>
    </label>
  );
};

export interface DisabledMessage {
  title: string;
  body: string;
}

export default CheckboxField;
