import axios from "axios";

import { PopupResponse } from "@/api/generatedTypes";

const PopupApi = {
  get: () => {
    return axios.get<PopupResponse>("/popup");
  },
};

export default PopupApi;
