import axios from "axios";

import {
  DeliveryAddress,
  Profile,
  RequestProfileUpdate,
  RequestProfileUploadPhoto,
  UserAddress,
} from "./generatedTypes";

const ProfileApi = {
  view: () => axios.get<Profile>("profile"),

  update: (fieldsToSubmit: RequestProfileUpdate) => {
    return axios.put<Profile>("profile", fieldsToSubmit);
  },

  uploadPhoto: (fieldsToSubmit: RequestProfileUploadPhoto) => {
    const formData = new FormData();
    formData.append("photo", fieldsToSubmit.photo);

    return axios.post<Profile>("profile/upload-photo", formData, {
      headers: {
        "Content-Type": fieldsToSubmit.photo.type,
      },
    });
  },

  viewAddresses: () => {
    return axios.get<UserAddress[]>("profile/addresses");
  },

  addAddress: (fieldsToSubmit: DeliveryAddress) => {
    return axios.post<UserAddress[]>("profile/addresses", fieldsToSubmit);
  },

  updateAddress: (id: string, fieldsToSubmit: DeliveryAddress) => {
    return axios.put<UserAddress[]>(`profile/addresses/${id}`, fieldsToSubmit);
  },

  deleteAddress: (id: string) => {
    return axios.delete<UserAddress[]>(`profile/addresses/${id}`);
  },
};

export default ProfileApi;
