import "style/main.scss";
import "swiper/css";
import "swiper/css/pagination";

import localFont from "@next/font/local";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AppProps } from "next/app";
import dynamic from "next/dynamic";
import { parseCookies } from "nookies";
import { RecoilRoot } from "recoil";

import { GlobalModalContainer } from "@/components/GlobalModalContainer";
import { RouteChangeLoader } from "@/components/RouteChangeLoader";
import { ToastHub } from "@/components/toaster";
import { TECH_WORKS_END_TIME, TECH_WORKS_ID } from "@/config";
import { setAxiosConfig } from "@/helpers/setAxiosConfig";
import { useReleaseControl } from "@/hooks/useReleaseControl";
import useTechWorks from "@/hooks/useTechWorks";
import { PromoPopup } from "@/components/PromoPopup";

const CookieWarning = dynamic(
  () => import("@/components/CookieWarning").then((mod) => mod.CookieWarning),
  { ssr: false }
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000,
      refetchOnWindowFocus: false,
    },
  },
});

const { token } = parseCookies();
setAxiosConfig(token);

const tStar = localFont({
  src: [
    {
      path: "../style/fonts/t-star/T-StarPro-Medium.woff2",
      weight: "500",
      style: "normal",
    },
    {
      path: "../style/fonts/t-star/T-StarPro-Bold.woff2",
      weight: "700",
      style: "normal",
    },
    {
      path: "../style/fonts/t-star/T-StarPro-Heavy.woff2",
      weight: "900",
      style: "normal",
    },
  ],
  display: "swap",
  fallback: ["sans-serif"],
});

function MyApp({ Component, pageProps }: AppProps) {
  const techWorksPopup = useTechWorks({
    id: TECH_WORKS_ID,
    endTime: TECH_WORKS_END_TIME,
  });

  useReleaseControl();

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${tStar.style.fontFamily};
        }
      `}</style>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <RecoilRoot>
          <Component {...pageProps} />
          <ToastHub />
          <GlobalModalContainer />
          <PromoPopup />
          <CookieWarning />
          {techWorksPopup}
        </RecoilRoot>
        <RouteChangeLoader />
      </QueryClientProvider>
    </>
  );
}

export default MyApp;
