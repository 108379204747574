import { MiniPopup } from "../MiniPopup";
import styles from "./PromoPopup.module.scss";
import { useMutation, useQuery } from "@tanstack/react-query";
import PopupApi from "@/api/PopupApi";
import { ChangeEvent, useEffect, useState } from "react";
import { CheckboxField } from "@/components/ui-components/CheckboxField";
import { MainButton } from "@/components/ui-components/MainButton";
import classNames from "classnames";
import { useToastHandlers } from "@/components/toaster";
import { useRouter } from "next/router";
import ProfileApi from "@/api/ProfileApi";
import { parseCookies } from "nookies";

const PromoPopup = () => {
  const [shown, setShown] = useState(false);
  const [email, setEmail] = useState("");

  const popupQuery = useQuery(["popup"], PopupApi.get);
  const { addToast } = useToastHandlers();
  const router = useRouter();

  const updateUserEmail = async () => {
    if (!email) return;
    return ProfileApi.update({ email });
  };

  const updateEmailMutation = useMutation(updateUserEmail, {
    onSuccess: (data) => {
      if (data?.status === 200) {
        closeAndRemember();
        showSuccessMessage();
      }
    },
    onError: () => {
      close();
      addToast({
        title: "не удалось сохранить e-mail",
        text: "проверьте email и попробуйте еще раз",
        variant: "base",
        delay: 10000,
      });
    },
  });

  const {
    body,
    btn_link,
    btn_text,
    email_form_enabled,
    image,
    success_body,
    success_title,
    title,
  } = popupQuery.data?.data || {};

  useEffect(() => {
    if (!title) return;
    optionallyShowPopup();
  }, [title]);

  const optionallyShowPopup = () => {
    const alreadyShown =
      window.localStorage.getItem(`promo_popup_title`) === title;
    const isGuest = parseCookies(null)?.role === "guest";
    if (alreadyShown || (isGuest && email_form_enabled)) return;
    setShown(true);
  };

  const close = () => {
    setShown(false);
  };

  const closeAndRemember = () => {
    window.localStorage.setItem(`promo_popup_title`, String(title));
    close();
  };

  const showSuccessMessage = () => {
    if (!success_title || !success_body) return;
    addToast({
      title: success_title,
      text: success_body,
      variant: "base",
    });
  };

  // skip if disabled or not loaded
  if (popupQuery.data?.status !== 200) return null;

  const navigate = () => {
    closeAndRemember();
    btn_link && router.push(btn_link);
  };

  const onEmailChange = (e: ChangeEvent<HTMLInputElement>) =>
    setEmail(e.target.value);

  const bottomBtnEnabled = !email_form_enabled && btn_link && btn_text;

  return (
    <MiniPopup
      shown={shown}
      onCloseIcon={closeAndRemember}
      closeIcon={true}
      extraClass={styles.root}
    >
      {Boolean(image) && (
        <div className={styles.imageContainer}>
          <div className={styles.imageFitter}>
            <img src={image} alt={title} className={styles.image} />
          </div>
        </div>
      )}
      <div className={styles.body}>
        <div className={styles.content}>
          <div className={styles.title}>{title}</div>
          <div dangerouslySetInnerHTML={{ __html: body as string }} />
        </div>

        {Boolean(email_form_enabled) && (
          <div className={styles.emailBlock}>
            <input
              type="email"
              className={styles.email}
              value={email}
              onChange={onEmailChange}
              placeholder="ВВЕДИТЕ E-MAIL*"
              required
            />
            <MainButton
              className={styles.button}
              onClick={updateEmailMutation.mutate}
              disabled={updateEmailMutation.isLoading}
            >
              ОТПРАВИТЬ
            </MainButton>
          </div>
        )}

        <CheckboxField
          label="Согласен(-на) на обработку персональных данных, ознакомлен(-на) с Политикой конфиденциальности"
          checked={true}
          toggleChecked={() => {}}
          disabled={true}
          className={styles.checkboxField}
        />

        {bottomBtnEnabled && (
          <MainButton
            className={classNames(styles.button, styles.bottomButton)}
            onClick={navigate}
          >
            {btn_text}
          </MainButton>
        )}
      </div>
    </MiniPopup>
  );
};

export default PromoPopup;
