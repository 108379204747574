import styles from "./BonusesLabel.module.scss";
import { JSX } from "react";
import { OnePlusOneLabel } from "@/components/ui-components/OnePlusOneLabel";

interface BonusesLabelProps {
  className?: string;
}

const BonusesLabel = ({ className = "" }: BonusesLabelProps) => {
  return (
    <OnePlusOneLabel
      className={className}
      title={
        <span>
          30%
          <br />
          <span className={styles.secondLine}>бонусами</span>
        </span>
      }
    />
  );
};

export default BonusesLabel;
