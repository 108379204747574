import Head from "next/head";
import { ReactNode } from "react";

import Favicons from "./Favicons";
import Script from "next/script";

export interface LayoutBaseProps {
  title?: string;
  children: ReactNode;
  className?: string;
}

const LayoutBase = ({ title, children, className }: LayoutBaseProps) => {
  return (
    <div className={className}>
      <Head>
        <title>{title ? `LOOK ONLINE. ${title}` : "LOOK ONLINE"}</title>
        <Favicons />
      </Head>
      {children}
      <Script id="retail-call-widget">{`
        var _rcct = "342cf24a936a17c76a4ff9b783dfab987df3a94df5e884eae736d2041a587a1e";
        !function (t) {
            var a = t.getElementsByTagName("head")[0];
            var c = t.createElement("script");
            c.type = "text/javascript";
            c.src = "//c.retailcrm.tech/widget/loader.js";
            a.appendChild(c);
        } (document);
      `}</Script>
    </div>
  );
};

export default LayoutBase;
